import { render, staticRenderFns } from "./BranchDataV2.vue?vue&type=template&id=73b23542&"
import script from "./BranchDataV2.vue?vue&type=script&lang=js&"
export * from "./BranchDataV2.vue?vue&type=script&lang=js&"
import style0 from "./BranchDataV2.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/zp_frontend_vuejs/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('73b23542')) {
      api.createRecord('73b23542', component.options)
    } else {
      api.reload('73b23542', component.options)
    }
    module.hot.accept("./BranchDataV2.vue?vue&type=template&id=73b23542&", function () {
      api.rerender('73b23542', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/BranchDataV2.vue"
export default component.exports