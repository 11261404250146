<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8"></base-header>
    <new-device-modal
      :showCreateDevice="showCreateDevice"
      :idDevice="idDevice"
      :clean="clean"
      :type="type"
      v-on:close="closeDeviceModal"
      v-on:error="handleError"
      v-on:device-created="newDevice"
      v-on:cleaned="clean = false"
      v-on:device-updated="deviceUpdated"
    ></new-device-modal>
    <div class="container-fluid mt--7">
      <div class="row mt-5 justify-content-center">
        <div class="col-xl-12">
          <card shadow>
            <form @submit.prevent>
              <b-tabs
                pills
                v-model="tabRootIndex"
                vertical
                content-class="mt-3"
                no-key-nav
              >
                <b-tab title="Detalles de la sucursal" title-item-class="text-center">
                  <b-tabs
                    pills
                    v-model="tabIndex"
                    content-class="mt-3"
                    no-key-nav
                  >
                    <b-tab title-item-class="flex-fill text-center" title="Generales">
                      <span slot="title">Generales</span>
                      <b-container style="width:100%" >
                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Nombre de la sucursal: *"
                              v-model="item.name"
                              :error = "validateField('name')"
                              :capitalize='true'
                              :maxlength="40"
                            ></base-input>
                          </b-col>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Clave interna:"
                              v-model="item.internalKey"
                              :error = "validateField('internalKey')"
                              :capitalize='true'
                              :maxlength="16"
                            ></base-input>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Nombre de contacto: *"
                              v-model="item.contactName"
                              :error = "validateField('contactName')"
                              :capitalize='true'
                              :maxlength="40"
                            ></base-input>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Número Telefonico: *"
                              v-model="item.contactPhoneNumber"
                              :error = "validateField('contactPhoneNumber')"
                              :maxlength="10"
                              validation="number"
                            ></base-input>
                          </b-col>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Extención:"
                              v-model="item.contactPhoneExt"
                              :error = "validateField('contactPhoneExt')"
                              :maxlength="10"
                              validation="number"
                            ></base-input>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Celular: *"
                              v-model="item.contactMobileNumber"
                              :error = "validateField('contactMobileNumber')"
                              :capitalize='true'
                              :maxlength="10"
                              validation="number"
                            ></base-input>
                          </b-col>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Email: *"
                              v-model="item.contactMail"
                              :error = "validateField('contactMail')"
                              :maxlength="64"
                            ></base-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Máximo de ventas por día:"
                              v-model="item.maxByDay"
                              :error = "validateField('maxByDay')"
                              validation="number"
                            ></base-input>
                          </b-col>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Máximo de ventas por mes:"
                              v-model="item.maxByMonth"
                              :error = "validateField('maxByMonth')"
                              validation="number"
                            ></base-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Máximo diario no presencial:"
                              v-model="item.maxByDayNoSwipped"
                              :error = "validateField('maxByDayNoSwipped')"
                              validation="number"
                            ></base-input>
                          </b-col>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Máximo mensual no presencial:"
                              v-model="item.maxByMonthNoSwipped"
                              :error = "validateField('maxByMonth')"
                              validation="number"
                            ></base-input>
                          </b-col>
                        </b-row>
                        




                      </b-container>
                        </b-tab>
                    <b-tab title-item-class="flex-fill text-center"  title='Direccion'>
                      <span slot="title">Dirección</span>
                      <b-container>
                        <b-row class="mt-4">
                          <b-row>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Código postal: * "
                                v-model="item.zipcode"
                                :error = "validateField('zipcode')"
                                :capitalize='true'
                                :maxlength="40"
                              ></base-input>
                            </b-col>
                            <b-col>
                              <base-input
                                input-classes="form-control-alternative"
                                label="Calle: * "
                                v-model="item.street"
                                :error = "validateField('street')"
                                :capitalize='true'
                                :maxlength="40"
                              ></base-input>
                            </b-col>
                          </b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Número ext: * "
                              v-model="item.extNumber"
                              :error = "validateField('extNumber')"
                              :capitalize='true'
                              :maxlength="10"
                            ></base-input>
                          </b-col>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Número int: "
                              v-model="item.intNumber"
                              :error = "validateField('intNumber')"
                              :capitalize='true'
                              :maxlength="10"
                            ></base-input>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Colonia: *"
                              v-model="item.suburb"
                              :error = "validateField('suburb')"
                              :capitalize='true'
                              :maxlength="40"
                            ></base-input>
                          </b-col>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Municipio: *"
                              v-model="item.county"
                              :error = "validateField('county')"
                              :capitalize='true'
                              :maxlength="40"
                            ></base-input>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Ciudad: * "
                              v-model="item.city"
                              :error = "validateField('city')"
                              :capitalize='true'
                              :maxlength="40"
                            ></base-input>
                          </b-col>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Estado: * "
                              v-model="item.state"
                              :error = "validateField('state')"
                              :capitalize='true'
                              :maxlength="40"
                            ></base-input>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Tipo Cliente (Marketing): *"
                              v-model="item.country"
                              :error = "validateField('country')"
                              :capitalize='true'
                              :maxlength="40"
                            ></base-input>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-tab>
                    <b-tab title-item-class="cflex-fill text-center"  title="Cuenta" v-if="false">
                      <span slot="title">Cuenta</span>
                      <b-container>
                        <b-row >
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Máximo de ventas por día:"
                              v-model="item.maxByDay"
                              :error = "validateField('maxByDay')"
                              validation="number"
                            ></base-input>
                          </b-col>
                          <b-col>
                            <base-input
                              input-classes="form-control-alternative"
                              label="Máximo de ventas por mes:"
                              v-model="item.maxByMonth"
                              :error = "validateField('maxByMonth')"
                              validation="number"
                            ></base-input>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-tab>

                    <b-tab title-item-class="flex-fill text-center"  title="Seguridad" >
                      <span slot="title">Seguridad</span>
                      <b-container>
                        <b-row class="mt-6 ">
                          <b-col>
                             <b-form-checkbox
                             style="margin-top:35px;"
                              v-model="item.pinRequired"
                              value="Y"
                              unchecked-value="N"
                            >
                              Seguridad
                            </b-form-checkbox>
                            <base-input
                              input-classes="form-control-alternative"
                              :error = "validateField('pinRequired')"
                              label="PIN"
                              v-model="item.pin"
                              :capitalize='true'
                              :maxlength="4"
                              validation="number"
                              :placeholder="placeHold"
                            ></base-input>
                            {{this.pinInfo}}
                          </b-col>
                          <!-- <b-col> -->
                            <!-- validador:   :error = "validateField('pinSecurity')"    -->
                          <!-- </b-col> -->

                           <b-col>
                           &nbsp;
                          </b-col>
                           <b-col>
                            <!-- Action list  Notempty-->
                            <b-form-group label="Acciones de seguridad:" v-if="item.actionsList.length > 0 && showEmptyActions == false">
                                <b-form-checkbox v-for="n in item.actionsList"  v-model="n.status" :key="n.actionId" value="A" unchecked-value="I">{{ n.description }} </b-form-checkbox>
                            </b-form-group>
                            <!-- Action list empty-->
                            <b-form-group label="Acciones de seguridad"  v-if=" showEmptyActions == true">
                              <b-form-checkbox-group
                                label="Acciones de seguridad:"
                                v-model="item.actionsList"
                                :options="optionstwo"
                                class="mb-3"
                                value-field="item"
                                text-field="name"
                                disabled-field="notEnabled"
                              ></b-form-checkbox-group>
                            </b-form-group>
                          </b-col>
                          <b-col>
                           &nbsp;
                          </b-col>
                          <!-- boton herencia -->
                          <!-- <b-col>
                            <b-form-checkbox
                             style="margin-top:35px; margin-left:-40px;"
                              v-model="item.inheritable"
                              value="Y"
                              unchecked-value="N"
                            >
                              Aplicar herencia
                            </b-form-checkbox>
                          </b-col> -->
                        </b-row>
                      </b-container>
                    </b-tab>

                    <b-tab title-item-class="flex-fill text-center"  title="Extras" >
                      <span slot="title">Extras</span>
                      <b-container>
                        <b-row class="mt-6 ">
                           <b-col>
                            <!-- Action list  Notempty-->
                            <b-form-group  label="Copias de Correo Comercio">
                                <b-form-checkbox  v-model="item.jsonConfig.sendMail.voucher" value="A" unchecked-value="I"> Voucher Comercio </b-form-checkbox>
                                <b-form-checkbox  v-model="item.jsonConfig.sendMail.cancellation" value="A" unchecked-value="I"> Voucher Cancelación </b-form-checkbox>
                                <b-form-checkbox  v-model="item.jsonConfig.sendMail.link" value="A" unchecked-value="I"> Voucher  Link</b-form-checkbox>
                            </b-form-group>
                            
                            <!-- <base-input
                              input-classes="form-control-alternative"
                              label="Url"
                              v-model="item.jsonConfig.sendMail.link"
                              :capitalize='true'
                              :placeholder="placeHold"
                            ></base-input> -->
                          </b-col>
                          <b-col>
                           &nbsp;
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-tab>
                  </b-tabs>
                  <div class="bg-white border-0">
                    <div class="row align-items-center">
                      <div class="col-12 text-right">
                        <base-button type="primary" class="my-4 ml-3 mr-3"  @click="nextStep" >Actualizar</base-button>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Dispositivos" title-item-class="text-center">
                  <devices-table
                    :idBranch="Number(idBranch)"
                    :updateTable="updateTable"
                    :deviceUpdated="deviceUpdateds"
                    :hiddeTotalsColumns="true"
                    vv-on:table-updated="tableUpdated"
                    v-on:new-item="newItem"
                    v-on:error="handleError"
                    v-on:update-item="prepareUpdateItem"
                    :type="type">
                  </devices-table>
                </b-tab>
              </b-tabs>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import handleErrorMixin from "../mixins/handleErrorMixin";
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
flatpickr.localize(Spanish);
import "flatpickr/dist/flatpickr.css";
import enumRoles from '../enums/enumRoles';
import { validateMail } from '../utils/utilMethods';
import { getBranchInfo, updateBranch, getActionsList  } from '../utils/api';
import { processErrorResponse } from '../mixins/axiosResponseMixin';
import { isEmptyOrNull, simpleCurrency } from '../utils/generalMethods';
import enums from '../enums/enums';
import DevicesTable from './Tables/DevicesTable.vue';
import NewDeviceModal from './Modals/NewDeviceModal.vue';
export default {
  mixins: [handleErrorMixin],
  components: {
    DevicesTable,
    NewDeviceModal
  },
  data() {
    return {
      rolAdmin: enumRoles.ADMIN,
      tabIndex: 0,
      numberTabs: 1,
      textButton: 'Siguiente',
      idBranch: 0,
      showUserModal: false,
      showErrorMessages: false,
      item: {
        enterpriseId: this.ownerId,
        name: '',
        contactName: '',
        contactPhoneNumber: '',
        contactPhoneExt: '',
        contactMobileNumber: '',
        contactMail: '',
        street: '',
        extNumber: '',
        intNumber: '',
        suburb: '',
        county: '',
        city: '',
        state: '',
        country: '',
        inheritable: '',
        internalKey: '',
        maxByDay: '',
        maxByMonth: '',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        zipcode: '',
        pinRequired:'',
        pin:'',
        actionsList:[],
        jsonConfig: {
          sendMail:{
            voucher: "", 
            cancellation: "", 
            link: ""
          }
        }
      },
      emptyItem: {
        enterpriseId: this.ownerId,
        name: '',
        contactName: '',
        contactPhoneNumber: '',
        contactPhoneExt: '',
        contactMobileNumber: '',
        contactMail: '',
        street: '',
        extNumber: '',
        intNumber: '',
        suburb: '',
        county: '',
        city: '',
        state: '',
        country: '',
        inheritable: '',
        internalKey: '',
        maxByDay: '',
        maxByMonth: '',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        zipcode: '',
        pinRequired:'',
        pin:'',
        actionsList:[],
        jsonConfig: {
          sendMail:{
            voucher: "", 
            cancellation: "", 
            link: ""
          }
        }
      },
      tabRootIndex: 0,
      updateTable: false,
      deviceUpdateds: {},
      type: enums.BRANCH,
      showCreateDevice: false,
      idDevice: 0,
      clean: Boolean,
      //izzy
      pinNumber:16542,
      passwordFieldType:'password',
      herence:'',
      actionsSecurity:[],
      optionstwo:[],
      showEmptyActions:false,
      securityTemp:"",
      pinInfo:"",
      placeHold:"",
    };
  },

  computed: {
    canalesP() {
      if (typeof this.operativas.tarjetaPresente.canal === "string") {
        return this.operativas.tarjetaPresente.canal.split(",");
      }
      return this.operativas.tarjetaPresente.canal;
    },
    canalesNP() {
      if (typeof this.operativas.tarjetaNoPresente.canal === "string") {
        return this.operativas.tarjetaNoPresente.canal.split(",");
      }
      return this.operativas.tarjetaNoPresente.canal;
    },
    userRole() {
      return this.$store.getters.jwtRole;
    }
  },

  methods: {
    updateActiveTab(event) {
      this.activeTab = event.title;
    },   
    currencyAmounts() {
      this.item.maxByDay = simpleCurrency(this.item.maxByDay)
      this.item.maxByMonth = simpleCurrency(this.item.maxByMonth)
    },
    previusStep() {
      this.tabIndex --;
    },
    nextStep() {
      if (!this.canContinue()) {
        this.$toasted.show('Es necesario completar todos los campos requeridos para continuar')
        return
      }
      this.saveAccount()
    },
    canContinue() {
      var keys = Object.keys(this.item)
      for (let i = 0; i < keys.length; i++) {
        if (this.validateField(keys[i]) !== '')
          return false;
      }
      return true;
    },
    saveAccount() {
      let item = this.removeCurrency()
      let loader = this.$loading.show({loader: 'dots'})
      item.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      updateBranch(item)
      .then(response => {
        loader.hide()
        if (response.data.statusCode === 200){
          this.$toasted.show('Sucursal actualizada correctamente')
        }else{
          processErrorResponse(response.data, this.$toasted)
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      })
    },
    clone(obj) {
        if (null == obj || "object" != typeof obj) return obj;
        var copy = obj.constructor();
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
        }
        return copy;
    },
    removeCurrency() {
      let copyItem = this.clone(this.item)
      copyItem.maxByDay = this.removeComma(copyItem.maxByDay)
      copyItem.maxByMonth = this.removeComma(copyItem.maxByMonth)
      copyItem.maxByDayNoSwipped = this.removeComma(copyItem.maxByDayNoSwipped)
      copyItem.maxByMonthNoSwipped = this.removeComma(copyItem.maxByMonthNoSwipped)
      return copyItem
    },
    removeComma(value){
      return parseInt(value.replaceAll(',',''))
    },
    validateField(key) {
      if (this.showErrorMessages) {
          switch (key) {
            case 'name':
              return this.item.name === '' ? 'Ingresa el nombre de la cuenta' : ''
            case 'contactName':
              return this.item.contactName === '' ? 'Ingresa el nombre de contacto' : ''
            case 'contactPhoneNumber':
              return this.item.contactPhoneNumber === '' 
              || this.item.contactPhoneNumber.length < 10 ? 'El número telefónico no es valido' : ''
            case 'contactMobileNumber':
              return this.item.contactMobileNumber === ''
              || this.item.contactMobileNumber.length < 10 ? 'El número de celular no es valido' : ''
            case 'contactMail':
              return !validateMail(this.item.contactMail) ? 'El e-mail no es valido' : ''
            case 'internalKey':
              return isEmptyOrNull(this.item.internalKey) ? 'Ingresa la clave interna' : ''
            case 'street':
              return this.item.street === '' ? 'Ingresa la calle' : ''
            case 'extNumber':
              return this.item.extNumber === '' ? 'Ingresa el número del domicilio' : ''
            case 'suburb':
              return this.item.suburb === '' ? 'Ingresa la colonia' : ''
            case 'county':
              return this.item.county === '' ? 'Ingresa el municipio' : ''
            case 'city':
              return this.item.city === '' ? 'Ingresa la ciudad' : ''
            case 'state':
              return this.item.state === '' ? 'Ingresa el estado' : ''
            case 'country':
              return this.item.country === '' ? 'Ingresa el tipo de cliente' : ''
            case 'zipcode':
              return isEmptyOrNull(this.item.zipcode) ? 'Ingresa el código postal' :  ''
            case 'maxByDay':
              return this.item.maxByDay === '' ? 'Ingresa la cantidad maxima por día' : ''
            case 'maxByMonth':
              return this.item.maxByMonth === '' ? 'Ingresa la cantidad maxima por mes' : ''
            case 'businessName':
              return this.item.businessName === '' ? 'Ingresa el nombre del negocio' : ''
            case 'pinRequired':
              if (this.item.pinRequired === "Y"){
                if (isEmptyOrNull(this.item.pin)){
                  return "Ingrese un PIN"
                }else if (this.item.pin.length < 4){
                    return "El pin debe contener 4 dígitos"
                }
                
              }
              return ""
            }
        return '';
      }
    },
    newItem() {
      this.showCreateDevice = true;
    },
    handleError(error) {
      this.$emit('error', error)
    },
    prepareUpdateItem(row) {
      this.idDevice = row.idUser;
      this.showCreateDevice = true;
    },
    closeDeviceModal() {
      this.clean = this.idDevice > 0
      this.showCreateDevice = false;
      this.idDevice = 0;
    },
    newDevice() {
      this.clean = true
      this.updateTable = true
      this.showCreateDevice = false;
    },
    deviceUpdated(event) {
      this.clean = true
      this.deviceUpdateds = event;
      this.idDevice = 0
      this.showCreateDevice = false;
    },
    switchVisibility() {
        this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
    },
    applyHerence(e){
      this.$toasted.show('herencia: '+e);
    },
    getActionsList() {
      getActionsList()
      .then(response => { 
        if (response.data){
            this.actionsSecurity = response.data.result;
            this.opcionestwo = this.actionsSecurity;
            for (let i = 0; i < this.opcionestwo.length; i++) {
              this.optionstwo.push({"item": {"actionId" : this.opcionestwo[i].actionId, "status":"A"},"name": this.opcionestwo[i].description});
            }
        }else{
          processErrorResponse(response.data, this.$toasted);
        }
      })
      .catch(error => {
        this.$emit('error', error)
      })
    },
  },
  async mounted() {
    if (this.$route.params.id > 0) {
      this.idBranch = this.$route.params.id;
      let loader = this.$loading.show({loader: 'dots'})
      getBranchInfo(this.idBranch)
      .then(response => {
        loader.hide()
        if (response.data.statusCode){
          this.showErrorMessages = true;
          //si no viene la info dde la branch aun los campos setear en 0
          let temp = response.data.result;
          temp.maxByDayNoSwipped = '0'
          temp.maxByMonthNoSwipped = '0'
          this.item = temp;
          
          //guarda status inicial del check de seguridad
           this.securityTemp = this.item.pinRequired;
          if(this.securityTemp=='Y'){this.pinInfo="PIN almacenado"
            this.placeHold="****";
            }

          this.currencyAmounts();

          var accionesTemp = response.data.result.actionsList;
          if(accionesTemp.length==0){
            this.showEmptyActions = true;
            this.getActionsList();
          }
        }else{
          processErrorResponse(response.data, this.$toasted);
        }
      })
      .catch(error => {
        loader.hide()
        this.$emit('error', error)
      });
      
    }

    
  }
};
</script>
<style>
.disabledTab {
  pointer-events: none;
}

.nav-pills .nav-item:not(:last-child) {
    padding-right: 0 !important;
}
</style>
