var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("base-header", {
        staticClass: "pb-6 pb-8 pt-5 pt-md-8",
        attrs: { type: "gradient-primary" }
      }),
      _c("new-device-modal", {
        attrs: {
          showCreateDevice: _vm.showCreateDevice,
          idDevice: _vm.idDevice,
          clean: _vm.clean,
          type: _vm.type
        },
        on: {
          close: _vm.closeDeviceModal,
          error: _vm.handleError,
          "device-created": _vm.newDevice,
          cleaned: function($event) {
            _vm.clean = false
          },
          "device-updated": _vm.deviceUpdated
        }
      }),
      _c("div", { staticClass: "container-fluid mt--7" }, [
        _c("div", { staticClass: "row mt-5 justify-content-center" }, [
          _c(
            "div",
            { staticClass: "col-xl-12" },
            [
              _c("card", { attrs: { shadow: "" } }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [
                    _c(
                      "b-tabs",
                      {
                        attrs: {
                          pills: "",
                          vertical: "",
                          "content-class": "mt-3",
                          "no-key-nav": ""
                        },
                        model: {
                          value: _vm.tabRootIndex,
                          callback: function($$v) {
                            _vm.tabRootIndex = $$v
                          },
                          expression: "tabRootIndex"
                        }
                      },
                      [
                        _c(
                          "b-tab",
                          {
                            attrs: {
                              title: "Detalles de la sucursal",
                              "title-item-class": "text-center"
                            }
                          },
                          [
                            _c(
                              "b-tabs",
                              {
                                attrs: {
                                  pills: "",
                                  "content-class": "mt-3",
                                  "no-key-nav": ""
                                },
                                model: {
                                  value: _vm.tabIndex,
                                  callback: function($$v) {
                                    _vm.tabIndex = $$v
                                  },
                                  expression: "tabIndex"
                                }
                              },
                              [
                                _c(
                                  "b-tab",
                                  {
                                    attrs: {
                                      "title-item-class":
                                        "flex-fill text-center",
                                      title: "Generales"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "title" },
                                        slot: "title"
                                      },
                                      [_vm._v("Generales")]
                                    ),
                                    _c(
                                      "b-container",
                                      { staticStyle: { width: "100%" } },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    label:
                                                      "Nombre de la sucursal: *",
                                                    error: _vm.validateField(
                                                      "name"
                                                    ),
                                                    capitalize: true,
                                                    maxlength: 40
                                                  },
                                                  model: {
                                                    value: _vm.item.name,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.name"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    label: "Clave interna:",
                                                    error: _vm.validateField(
                                                      "internalKey"
                                                    ),
                                                    capitalize: true,
                                                    maxlength: 16
                                                  },
                                                  model: {
                                                    value: _vm.item.internalKey,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "internalKey",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.internalKey"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    label:
                                                      "Nombre de contacto: *",
                                                    error: _vm.validateField(
                                                      "contactName"
                                                    ),
                                                    capitalize: true,
                                                    maxlength: 40
                                                  },
                                                  model: {
                                                    value: _vm.item.contactName,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "contactName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.contactName"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    label:
                                                      "Número Telefonico: *",
                                                    error: _vm.validateField(
                                                      "contactPhoneNumber"
                                                    ),
                                                    maxlength: 10,
                                                    validation: "number"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.item
                                                        .contactPhoneNumber,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "contactPhoneNumber",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.contactPhoneNumber"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    label: "Extención:",
                                                    error: _vm.validateField(
                                                      "contactPhoneExt"
                                                    ),
                                                    maxlength: 10,
                                                    validation: "number"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.item.contactPhoneExt,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "contactPhoneExt",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.contactPhoneExt"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    label: "Celular: *",
                                                    error: _vm.validateField(
                                                      "contactMobileNumber"
                                                    ),
                                                    capitalize: true,
                                                    maxlength: 10,
                                                    validation: "number"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.item
                                                        .contactMobileNumber,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "contactMobileNumber",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.contactMobileNumber"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    label: "Email: *",
                                                    error: _vm.validateField(
                                                      "contactMail"
                                                    ),
                                                    maxlength: 64
                                                  },
                                                  model: {
                                                    value: _vm.item.contactMail,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "contactMail",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.contactMail"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    label:
                                                      "Máximo de ventas por día:",
                                                    error: _vm.validateField(
                                                      "maxByDay"
                                                    ),
                                                    validation: "number"
                                                  },
                                                  model: {
                                                    value: _vm.item.maxByDay,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "maxByDay",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.maxByDay"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    label:
                                                      "Máximo de ventas por mes:",
                                                    error: _vm.validateField(
                                                      "maxByMonth"
                                                    ),
                                                    validation: "number"
                                                  },
                                                  model: {
                                                    value: _vm.item.maxByMonth,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "maxByMonth",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.maxByMonth"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    label:
                                                      "Máximo diario no presencial:",
                                                    error: _vm.validateField(
                                                      "maxByDayNoSwipped"
                                                    ),
                                                    validation: "number"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.item
                                                        .maxByDayNoSwipped,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "maxByDayNoSwipped",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.maxByDayNoSwipped"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    label:
                                                      "Máximo mensual no presencial:",
                                                    error: _vm.validateField(
                                                      "maxByMonth"
                                                    ),
                                                    validation: "number"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.item
                                                        .maxByMonthNoSwipped,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "maxByMonthNoSwipped",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.maxByMonthNoSwipped"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-tab",
                                  {
                                    attrs: {
                                      "title-item-class":
                                        "flex-fill text-center",
                                      title: "Direccion"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "title" },
                                        slot: "title"
                                      },
                                      [_vm._v("Dirección")]
                                    ),
                                    _c(
                                      "b-container",
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "mt-4" },
                                          [
                                            _c(
                                              "b-row",
                                              [
                                                _c(
                                                  "b-col",
                                                  [
                                                    _c("base-input", {
                                                      attrs: {
                                                        "input-classes":
                                                          "form-control-alternative",
                                                        label:
                                                          "Código postal: * ",
                                                        error: _vm.validateField(
                                                          "zipcode"
                                                        ),
                                                        capitalize: true,
                                                        maxlength: 40
                                                      },
                                                      model: {
                                                        value: _vm.item.zipcode,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.item,
                                                            "zipcode",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.zipcode"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-col",
                                                  [
                                                    _c("base-input", {
                                                      attrs: {
                                                        "input-classes":
                                                          "form-control-alternative",
                                                        label: "Calle: * ",
                                                        error: _vm.validateField(
                                                          "street"
                                                        ),
                                                        capitalize: true,
                                                        maxlength: 40
                                                      },
                                                      model: {
                                                        value: _vm.item.street,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.item,
                                                            "street",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.street"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    label: "Número ext: * ",
                                                    error: _vm.validateField(
                                                      "extNumber"
                                                    ),
                                                    capitalize: true,
                                                    maxlength: 10
                                                  },
                                                  model: {
                                                    value: _vm.item.extNumber,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "extNumber",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.extNumber"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    label: "Número int: ",
                                                    error: _vm.validateField(
                                                      "intNumber"
                                                    ),
                                                    capitalize: true,
                                                    maxlength: 10
                                                  },
                                                  model: {
                                                    value: _vm.item.intNumber,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "intNumber",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.intNumber"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    label: "Colonia: *",
                                                    error: _vm.validateField(
                                                      "suburb"
                                                    ),
                                                    capitalize: true,
                                                    maxlength: 40
                                                  },
                                                  model: {
                                                    value: _vm.item.suburb,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "suburb",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.suburb"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    label: "Municipio: *",
                                                    error: _vm.validateField(
                                                      "county"
                                                    ),
                                                    capitalize: true,
                                                    maxlength: 40
                                                  },
                                                  model: {
                                                    value: _vm.item.county,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "county",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.county"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    label: "Ciudad: * ",
                                                    error: _vm.validateField(
                                                      "city"
                                                    ),
                                                    capitalize: true,
                                                    maxlength: 40
                                                  },
                                                  model: {
                                                    value: _vm.item.city,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "city",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.city"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    label: "Estado: * ",
                                                    error: _vm.validateField(
                                                      "state"
                                                    ),
                                                    capitalize: true,
                                                    maxlength: 40
                                                  },
                                                  model: {
                                                    value: _vm.item.state,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "state",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.state"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    label:
                                                      "Tipo Cliente (Marketing): *",
                                                    error: _vm.validateField(
                                                      "country"
                                                    ),
                                                    capitalize: true,
                                                    maxlength: 40
                                                  },
                                                  model: {
                                                    value: _vm.item.country,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "country",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.country"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                false
                                  ? _c(
                                      "b-tab",
                                      {
                                        attrs: {
                                          "title-item-class":
                                            "cflex-fill text-center",
                                          title: "Cuenta"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "title" },
                                            slot: "title"
                                          },
                                          [_vm._v("Cuenta")]
                                        ),
                                        _c(
                                          "b-container",
                                          [
                                            _c(
                                              "b-row",
                                              [
                                                _c(
                                                  "b-col",
                                                  [
                                                    _c("base-input", {
                                                      attrs: {
                                                        "input-classes":
                                                          "form-control-alternative",
                                                        label:
                                                          "Máximo de ventas por día:",
                                                        error: _vm.validateField(
                                                          "maxByDay"
                                                        ),
                                                        validation: "number"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.item.maxByDay,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.item,
                                                            "maxByDay",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.maxByDay"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-col",
                                                  [
                                                    _c("base-input", {
                                                      attrs: {
                                                        "input-classes":
                                                          "form-control-alternative",
                                                        label:
                                                          "Máximo de ventas por mes:",
                                                        error: _vm.validateField(
                                                          "maxByMonth"
                                                        ),
                                                        validation: "number"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.item.maxByMonth,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.item,
                                                            "maxByMonth",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.maxByMonth"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "b-tab",
                                  {
                                    attrs: {
                                      "title-item-class":
                                        "flex-fill text-center",
                                      title: "Seguridad"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "title" },
                                        slot: "title"
                                      },
                                      [_vm._v("Seguridad")]
                                    ),
                                    _c(
                                      "b-container",
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "mt-6 " },
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    staticStyle: {
                                                      "margin-top": "35px"
                                                    },
                                                    attrs: {
                                                      value: "Y",
                                                      "unchecked-value": "N"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.item.pinRequired,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.item,
                                                          "pinRequired",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.pinRequired"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                            Seguridad\n                          "
                                                    )
                                                  ]
                                                ),
                                                _c("base-input", {
                                                  attrs: {
                                                    "input-classes":
                                                      "form-control-alternative",
                                                    error: _vm.validateField(
                                                      "pinRequired"
                                                    ),
                                                    label: "PIN",
                                                    capitalize: true,
                                                    maxlength: 4,
                                                    validation: "number",
                                                    placeholder: _vm.placeHold
                                                  },
                                                  model: {
                                                    value: _vm.item.pin,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.item,
                                                        "pin",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.pin"
                                                  }
                                                }),
                                                _vm._v(
                                                  "\n                          " +
                                                    _vm._s(this.pinInfo) +
                                                    "\n                        "
                                                )
                                              ],
                                              1
                                            ),
                                            _c("b-col", [
                                              _vm._v(
                                                "\n                          \n                        "
                                              )
                                            ]),
                                            _c(
                                              "b-col",
                                              [
                                                _vm.item.actionsList.length >
                                                  0 &&
                                                _vm.showEmptyActions == false
                                                  ? _c(
                                                      "b-form-group",
                                                      {
                                                        attrs: {
                                                          label:
                                                            "Acciones de seguridad:"
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.item.actionsList,
                                                        function(n) {
                                                          return _c(
                                                            "b-form-checkbox",
                                                            {
                                                              key: n.actionId,
                                                              attrs: {
                                                                value: "A",
                                                                "unchecked-value":
                                                                  "I"
                                                              },
                                                              model: {
                                                                value: n.status,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    n,
                                                                    "status",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "n.status"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  n.description
                                                                ) + " "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.showEmptyActions == true
                                                  ? _c(
                                                      "b-form-group",
                                                      {
                                                        attrs: {
                                                          label:
                                                            "Acciones de seguridad"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-checkbox-group",
                                                          {
                                                            staticClass: "mb-3",
                                                            attrs: {
                                                              label:
                                                                "Acciones de seguridad:",
                                                              options:
                                                                _vm.optionstwo,
                                                              "value-field":
                                                                "item",
                                                              "text-field":
                                                                "name",
                                                              "disabled-field":
                                                                "notEnabled"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.item
                                                                  .actionsList,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.item,
                                                                  "actionsList",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.actionsList"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            ),
                                            _c("b-col", [
                                              _vm._v(
                                                "\n                          \n                        "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-tab",
                                  {
                                    attrs: {
                                      "title-item-class":
                                        "flex-fill text-center",
                                      title: "Extras"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "title" },
                                        slot: "title"
                                      },
                                      [_vm._v("Extras")]
                                    ),
                                    _c(
                                      "b-container",
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "mt-6 " },
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label:
                                                        "Copias de Correo Comercio"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        attrs: {
                                                          value: "A",
                                                          "unchecked-value": "I"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.item.jsonConfig
                                                              .sendMail.voucher,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.item
                                                                .jsonConfig
                                                                .sendMail,
                                                              "voucher",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.jsonConfig.sendMail.voucher"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Voucher Comercio "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        attrs: {
                                                          value: "A",
                                                          "unchecked-value": "I"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.item.jsonConfig
                                                              .sendMail
                                                              .cancellation,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.item
                                                                .jsonConfig
                                                                .sendMail,
                                                              "cancellation",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.jsonConfig.sendMail.cancellation"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Voucher Cancelación "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-form-checkbox",
                                                      {
                                                        attrs: {
                                                          value: "A",
                                                          "unchecked-value": "I"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.item.jsonConfig
                                                              .sendMail.link,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.item
                                                                .jsonConfig
                                                                .sendMail,
                                                              "link",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.jsonConfig.sendMail.link"
                                                        }
                                                      },
                                                      [_vm._v(" Voucher  Link")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c("b-col", [
                                              _vm._v(
                                                "\n                          \n                        "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("div", { staticClass: "bg-white border-0" }, [
                              _c(
                                "div",
                                { staticClass: "row align-items-center" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 text-right" },
                                    [
                                      _c(
                                        "base-button",
                                        {
                                          staticClass: "my-4 ml-3 mr-3",
                                          attrs: { type: "primary" },
                                          on: { click: _vm.nextStep }
                                        },
                                        [_vm._v("Actualizar")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "b-tab",
                          {
                            attrs: {
                              title: "Dispositivos",
                              "title-item-class": "text-center"
                            }
                          },
                          [
                            _c("devices-table", {
                              attrs: {
                                idBranch: Number(_vm.idBranch),
                                updateTable: _vm.updateTable,
                                deviceUpdated: _vm.deviceUpdateds,
                                hiddeTotalsColumns: true,
                                "vv-on:table-updated": "tableUpdated",
                                type: _vm.type
                              },
                              on: {
                                "new-item": _vm.newItem,
                                error: _vm.handleError,
                                "update-item": _vm.prepareUpdateItem
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }